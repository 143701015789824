.demo1 {
  padding: 16px;
  display: block;
  color: black;
  border-bottom: 1px solid #ccc;
}
.demo1:hover {
  background-color: #f1f1f1 !important;
}
.activegrey {
  background-color: #f1f1f1 !important;
}
.container1 {
  padding: 0.1px 16px;
}
.divflex {
  display: inline-flex;
  align-items: center;
}
.imgs1 {
  width: 15%;
  border-radius: 4px !important;
  margin-right: 16px;
}
h6 {
  font-size: 15px;
  margin: 10px 0px;
  font-weight: 400;
}
.container1 span {
  font-size: 20px !important;
  opacity: 0.6;
}
