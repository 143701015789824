.bgModel {
  position: fixed;
  z-index: 4;
  display: block;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
  padding-top: 100px;
}
.modal {
  margin: auto;
  background-color: #fff;
}

.modalhead {
  background-color: #f44336;
  color: #fff;
  padding: 8px 14px !important;
  padding: 0.01em 14px;
}
.modalhead h2 {
  display: block;
  font-size: 30px;
  font-weight: 500;
}
hr {
  border: 0;
  border-top: 1px solid #eee;
  margin: 20px 0;
}
@media screen and (min-width: 993px) {
  .modal {
    width: 900px;
  }
}
@media screen and (max-width: 993px) {
  .modal {
    width: 600px;
  }
}
@media screen and (max-width: 765px) {
  .bgModel {
    padding-top: 50px;
  }
  .modal {
    width: 500px;
  }
}
@media screen and (max-width: 493px) {
  .modal {
    margin: 0 10px;
    width: auto !important;
  }
  .inputfield {
    width: 97.5%;
  }
}
.inputfield {
  display: block;
  padding: 10px;
  border: 1px solid #ccc;
  width: 97.5%;
  margin-bottom: 16px;
}
.faremove {
  float: right !important;
  font-size: 36px !important;
  user-select: none;
  padding: 16px 16px;
}
.panel {
  display: block;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 0.16px 16px;
}


.buttonsection {
  margin-top: 16px !important;
  margin-bottom: 16px !important;
}
.cancelbtn {
  color: black;
  padding: 8px 16px;
  border: none;
  display: inline-block;
  text-decoration: none;
  align-items: center;
  overflow: hidden;
}
.bgRed {
  color: #fff;
  background-color: #f44336;
}
.cancelbtn:hover {
  color: black;
  background-color: #ccc;
}
